<template>
  <Navbar/>
  <Banner/>
</template>

<script>
import Navbar from "./Navbar";
import Banner from "./Banner";

export default {
  name: "Header",
  components: {Banner, Navbar}
}
</script>