<template>
  <section class="main">
    <div class="container">
      <div class="main-section _circles">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4">
            <h2 class="h2 text-center text-md-start">
              Широкий выбор форматов консультации
            </h2>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-5">
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/support.svg" alt="Устная консультация">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Устная консультация
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Наши юристы проконсультируют любым удобным для вас голосовым способом связи
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/consultation.svg" alt="Письменная консультация">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Письменная консультация
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Наши юристы проконсультируют любым удобным для вас письменным способом связи
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/support-2.svg" alt="Содействие в переговорах">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Содействие в переговорах
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Наши сотрудники примут участие в переговорах и окажут соответствующую помощь
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/support-3.svg" alt="Обзор законодательства">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Обзор законодательства
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Рассмотрим вместе и объясним в случае необходимости изменения в законодательстве
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/support-4.svg" alt="Предоставление договоров">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Предоставление договоров
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Подготовим и предоставим типовые договоры и инструкции по их составлению
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto ms-md-0">
                <img src="/assets/svg/clipboard.svg" alt="Письменное заключение">
              </div>
              <div class="circles-item_name text-center text-md-start mb-2">
                Письменное заключение
              </div>
              <div class="circles-item_describe text-center text-md-start">
                Предоставим письменное заключение по запуску бизнес проекта
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section">
        <div class="card">
          <div class="row align-items-center align-items-md-end align-items-xl-center">
            <div class="col-12 col-md-6 col-lg-5 order-1 order-sm-0 d-flex">
              <img src="/assets/png/support-image.png" class="img-fluid card_images mt-auto"
                   alt="Решение вопроса в момент его возникновения">
            </div>
            <div class="col-12 col-md-6 col-lg-7 order-0 order-sm-1">
              <h3 class="h3 mb-4">
                И еще
              </h3>
              <ul class="list _circle">
                <li>Решение вопроса в момент его возникновения</li>
                <li>Не нужно предварительно записываться и ждать в очереди</li>
                <li>Можно получить услугу в любое время и в любом месте</li>
                <li>Психологически легче рассказывать о проблеме по телефону</li>
                <li>Клиент может передать телефон для представления юристом его интересов</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gr" ref="blockScroll">
      <div class="container">
        <div class="main-section _sm_p">
          <div class="row">
            <div class="col-12 mb-4 mb-md-5">
              <h2 class="h2 text-center text-md-start">Топ вопросов по бизнесу</h2>
              <h5 class="h5">отвечает личный юрист</h5>
            </div>
            <div class="col-12">
              <ul class="list _line mb-5">
                <li v-for="(li, inx) in allList ? list : list.slice(0, 8)" :key="inx">
                  {{ li }}
                </li>
              </ul>
              <div class="btn-more" :class="allList ? 'open' : 'close'" @click="changeListView">
                <span class="arrow me-3"></span>
                {{ allList ? 'Скрыть' : 'Показать все' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="main-section _sm_p">
        <div class="main-section_banner">
          <div class="row justify-content-end">
            <div class="col-12 col-md-7">
              <h3 class="h3 mb-5">
                Связывайтесь с юристом
              </h3>
              <div class="row row-cols-1 row-cols-lg-2 g-4">
                <div class="col">
                  <a href="tel:+37517 336 7650" class="social-connect">
                    <div class="social-connect_image mb-auto me-3">
                      <img src="/assets/svg/phone-call-2.svg">
                    </div>
                    <div class="social-connect_describe">
                      <div class="name _big">7650 (А1, МТС, Life)</div>
                      <div class="text">+37517 336 7650</div>
                    </div>
                  </a>
                </div>
                <div class="col">
                  <a href="mailto:info@els24.by" class="social-connect">
                    <div class="social-connect_image mb-auto me-3">
                      <img src="/assets/svg/round-email.svg">
                    </div>
                    <div class="social-connect_describe">
                      <div class="name _big">info@els24.by</div>
                      <div class="text">Электронная почта</div>
                    </div>
                  </a>
                </div>
                <div class="col">
                  <a href="skype:urist24.online" class="social-connect">
                    <div class="social-connect_image mb-auto me-3">
                      <img src="/assets/svg/skype.svg">
                    </div>
                    <div class="social-connect_describe">
                      <div class="name _big">urist24.online</div>
                      <div class="text">В скайпе</div>
                    </div>
                  </a>
                </div>
                <div class="col">
                  <a href="https://new-cp.els24.com/login" class="social-connect" target="_blank">
                    <div class="social-connect_image my-auto me-3">
                      <img src="/assets/svg/cursor.svg">
                    </div>
                    <div class="social-connect_describe">
                      <div class="text fs-l">В чате личного кабинета</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section _sm_p">
        <div class="row row-cols-1 row-cols-lg-3 g-0">
          <div class="col">
            <div class="card_bg _dark">
              <h4 class="h4 mb-4">
                Время действия продукта
              </h4>
              <div>
                1 год со следующего дня за днем активации. Активация происходит автоматически после оформления и
                оплаты сервиса (счета на услуги)
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card_bg _middle">
              <h4 class="h4 mb-4">
                Предоставляемые услуги
              </h4>
              <div>
                Количество предоставляемых услуг сервисом «Личный Юрист» устанавливаются согласно выбранному тарифному
                плану
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card_bg _light">
              <h4 class="h4 mb-4">
                Территория действия
              </h4>
              <div>
                Оказание услуг происходит на территории Республики Беларусь (звонки по всему миру)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section _circles">
        <div class="row align-content-center">
          <div class="col-12 col-md-12 col-lg-12">
            <h2 class="h2 text-center">
              Время предоставления услуги
            </h2>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-5">
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto">
                <img src="/assets/svg/phone-call-2.svg" alt="В момент обращения">
              </div>
              <div class="circles-item_name text-center mb-2">
                В момент обращения
              </div>
              <div class="circles-item_describe text-center">
                Юрист устно отвечает на вопрос Клиента в момент обращения, которое не требует детальной проработки
                вопроса и изучения документов
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto">
                <img src="/assets/svg/deal.svg" alt="До 24 часов">
              </div>
              <div class="circles-item_name text-center mb-2">
                До 24 часов
              </div>
              <div class="circles-item_describe text-center">
                При необходимости детальнее проработать вопрос, рассмотреть практику применения или проработать
                законодательство. Клиент получает устный ответ в оговоренные сроки
              </div>
            </div>
          </div>
          <div class="col">
            <div class="circles-item">
              <div class="circles-item_image mb-3 me-auto ms-auto">
                <img src="/assets/svg/contract.svg" alt="До 48 часов">
              </div>
              <div class="circles-item_name text-center mb-2">
                До 48 часов
              </div>
              <div class="circles-item_describe text-center">
                Юрист даёт Клиенту письменное правовое заключение, рекомендует определенную последовательность действий
                с разъяснениями в сопроводительном письме
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section">
        <div class="card py-5">
          <div class="row align-items-center">
            <div class="col-12">
              <h3 class="h3 mb-4">
                О сервисе «Личный Юрист»
              </h3>
              <div class="fs-l">
                ОДО «Столичное экспертно-правовое агентство»
                представляет на рынке Республики Беларусь
                уникальный сервис круглосуточной юридической
                поддержки юридических лиц и индивидуальных
                предпринимателей «Личный юрист». Штат юристов
                Компании состоит из специалистов-практиков с
                многолетним опытом работы в различных отраслях
                права. ОДО «Столичное экспертно-правовое
                агентство» оказывает юридические услуги в
                соответствии с лицензией №02240/2071, выданной
                Министерством юстиции Республики Беларусь
                26.01.2017 г.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="bg-gr main-section-swiper">-->
<!--      <h5 class="h5 text-center mb-5">наши партнеры</h5>-->
<!--      <swiper-->
<!--          :slidesPerView="3"-->
<!--          :spaceBetween="30"-->
<!--          :loop="true"-->
<!--          :autoplay="{-->
<!--      delay: 1000,-->
<!--      disableOnInteraction: false,-->
<!--    }"-->
<!--      >-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href" href="https://www.priorbank.by/" target="_blank" referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-1.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href"-->
<!--             href="https://www.vtb.by/malomu-biznesu/partnerskie-servisy-dlya-biznesa/lichnyy-yurist-dlya-biznesa"-->
<!--             target="_blank" referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-2.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href" href="https://belapb.els24.by/" target="_blank" referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-3.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href"-->
<!--             href="https://www.rrb.by/korporativnim-klientam/yuridicheskie-uslugi-v-rezhime-247" target="_blank"-->
<!--             referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-4.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href" href="https://tb.by/business/rko/affiliateprograms/" target="_blank"-->
<!--             referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-5.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--        <swiper-slide class="d-flex">-->
<!--          <a class="m-auto swiper-href" href="https://belvebhub.by/services/juridical-support/landing" target="_blank"-->
<!--             referrerpolicy="no-referrer">-->
<!--            <img class="img-fluid" src="/assets/logos/logo-6.svg">-->
<!--          </a>-->
<!--        </swiper-slide>-->
<!--      </swiper>-->
<!--    </div>-->
  </section>
</template>


<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Autoplay} from "swiper";

// Import Swiper styles
import 'swiper/swiper.scss'

SwiperCore.use([Autoplay]);

export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      list: [
        'Вы выиграли спор в суде, но судебное решение не исполняется',
        'Последствия за несвоевременную подачу декларации',
        'Вам нужны внутренние корпоративные документы',
        'Контрагент задолжал вашей компании, и вы хотите взыскать долг',
        'Вы проводите реорганизацию и нужна помощь с документами',
        'Вам нужно разобраться с трудовыми вопросами или налогообложением',
        'Вы собираетесь участвовать в тендере',
        'Принимаете на работу сотрудников',
        'Необходимо защитить интеллектуальную собственность вашей компании',
        'Нужно продать имущество юридического лица',
        'Хотите внести изменения в учредительные документы или сменить руководителя',
        'Необходимо поменять юридический адрес компании',
        'Заключаете договор с контрагентом',
        'Необходимо согласовать размещение рекламы',
        'К вам пришла проверка',
        'Есть вопросы, связанные с продажей долей в юридическом лице',
        'Экспортируете товар заграницу',
        'На вас пожаловались - что делать',
        'Требования заказчиков к участникам госзакупок',
        'Нюансы вывода дивидендов',
        'Использование автотранспорта и нюансы оформления',
        'Предмет лизинга вышел из строя',
        'Переплатили сотруднику при окончательном расчете',
        'Налог на доходы иностранных организаций',
        'Контрагент ушел в банкротство или ликвидацию',
        'Легализация самовольно возведенных строений',
        'Матответственность сотрудников и использование имущества работодателя в личных целях',
        'Возмещение причиненного ущерба собственности компании',
        'Нарушение техники безопасности',
        'И многие другие ситуации в жизни компании',
      ],
      allList: false,
    }
  },
  methods: {
    changeListView: function () {
      this.allList = !this.allList;
      this.$refs.blockScroll.scrollIntoView();
    },
  }
}
</script>
<style lang="scss">
.main-section {
  padding: 60px 0;

  &._sm_p {
    padding: 40px 0;
  }

  &-swiper {
    padding: 48px 0;
  }

  &._circles {
    h2, .h2 {
      margin: 0 0 65px 0;
    }
  }

  &_banner {
    border-radius: 5px;
    background: url(/assets/jpg/section-banner-bg-mob.jpg) no-repeat bottom center;
    background-size: cover;
    padding: 20px 24px 250px 24px;
    @include _768 {
      padding: 48px 66px;
      background: url(/assets/png/section-banner-bg.png) no-repeat left -250px center;
      background-size: cover;
    }
    @include _1200 {
      padding: 48px 66px;
      background: url(/assets/png/section-banner-bg.png) no-repeat left center;
      background-size: cover;
    }
  }
}

.swiper-href {
  img {
    max-height: 30px;
    @include _992 {
      max-height: 100%;
    }
  }
}
</style>