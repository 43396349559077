<template>
  <nav class="navbar navbar-expand-lg navbar-main" :class="menuOpen ? 'active' : ''">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="/assets/svg/logo.svg">
      </a>
      <button class="navbar-toggler d-flex d-lg-none" :class="menuOpen ? 'active' : ''"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarContent"
              aria-controls="navbarContent"
              aria-expanded="false"
              @click="openMenu"
      >
        <span class="navbar-toggler_burger"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-auto d-flex align-items-center">
          <li class="nav-item d-none d-lg-flex">
            <a class="nav-link" href="tel:+37517 336 7650">
              <div class="fs-m fw-bold">+37517 336 7650</div>
              <div class="fs-s">7650 (A1, МТС, Life)</div>
            </a>
          </li>
          <li class="nav-item _split d-flex d-lg-none">
            <a class="nav-link" href="tel:+37517 336 7650">
              <div class="title">7650 (A1, МТС, Life)</div>
              <div class="decribe">+37517 336 7650</div>
            </a>
          </li>
          <li class="nav-item mt-auto mt-lg-0 mb-5 mb-lg-0">
            <a class="nav-btn fs-s fw-bold  mb-4 mb-lg-0" href="https://new-cp.els24.by/login" target="_blank">
              <span class="mx-auto">Личный кабинет</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    openMenu: function () {
      this.menuOpen = !this.menuOpen;

      if (this.menuOpen) {
        document.querySelector('html').style.overflowY = 'hidden';
      } else {
        document.querySelector('html').style.overflowY = 'scroll';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-main {
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(240, 241, 244, 0.9);
  padding: 11px 0;

  &.active {
    .navbar-collapse {
      .navbar-nav {
        min-height: calc(100vh - 76px);
      }
    }
  }

  .nav-item {
    width: 100%;
    @include _992 {
      width: auto;
    }

    &._split {
      padding: 30px 0;
      margin: 12px 0 0 0;
      border-top: 1px solid #C4C4C4;
      border-bottom: 1px solid #C4C4C4;
    }

    &:first-child {
      padding: 0 24px 0 0;
      margin: 0 24px 0 0;
      position: relative;

      &:after {
        content: '';
        height: 50%;
        width: 1px;
        background: #CACACA;
        position: absolute;
        top: 25%;
        right: 0;
      }
    }

    .nav-link {
      padding: 0;
      color: #151D30;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 41px;
        color: #151D30;
        text-align: left;
      }

      .decribe {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        color: #151D30;
      }
    }
  }

  .nav-btn {
    border: 1.5px solid #257ACC;
    border-radius: 4px;
    background: #257ACC;
    color: white;
    width: 100%;
    text-decoration: none;
    padding: 12px 24px;
    display: flex;
    @include _992 {
      border: 1.5px solid #257ACC;
      border-radius: 4px;
      text-decoration: none;
      padding: 12px 24px;
    }
  }
}

.navbar-toggler {
  box-shadow: none;
  outline: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  width: 20px;
  height: 20px;
  position: relative;
  //display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &_burger {
    width: 20px;
    height: 2px;
    background: black;
    transition: all .2s ease-in-out;
    position: absolute;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      left: 0;
      background: black;
      transition: all .2s ease-in-out;
    }

    &:before {
      transform: translateY(-6px);
    }

    &:after {
      transform: translateY(6px);
    }
  }

  &.active {
    .navbar-toggler_burger {
      transform: translateX(-50px);
      background: transparent;

      &:before {
        transform: rotate(45deg) translate(35px, -35px);
        background: #C4C4C4;
      }

      &:after {
        transform: rotate(-45deg) translate(35px, 35px);
        background: #C4C4C4;
      }
    }
  }

}
</style>