<template>
  <Header/>
  <Main/>
  <Footer/>
  <div class="modal fade" id="attModal" ref="attModal" tabindex="-1" aria-labelledby="attModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <strong>Уважаемые клиенты</strong>,
          В связи с техническими работами провайдера будут перебои с телефонной связью. По всем вопросам просим звонить
          по телефону
          <a href="tel:+375291018680">
            <strong>+375291018680</strong>.
          </a>
          <br>
          <br>
          Приносим свои извинения за предоставленные неудобства.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header/Index";
import Footer from "./components/Footer/Index";
import Main from "./components/Main/Index";
import {Modal} from 'bootstrap'

export default {
  name: 'App',
  components: {Main, Footer, Header},
  // mounted() {
  //   new Modal(this.$refs.attModal).show();
  // }
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #151D30;
  line-height: initial;
}
</style>
