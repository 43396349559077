<template>
  <section class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-2">
          <a href="#" class="d-flex mb-5 mb-md-0">
            <img src="/assets/svg/logo-light.svg">
          </a>
        </div>
        <div class="col-12 col-md-4 col-lg-6">
          <a href="tel:+37517 336 7650"
             class="footer-text footer-text_icon mb-4"
          >
            <img class="me-2" src="/assets/svg/phone-call.svg">
            <span>
             7650 (А1, МТС, life:)<br>
              <strong>+37517 336 7650 </strong>— Круглосуточное обслуживание клиентов
           </span>
          </a>
          <a href="mailto: info@els24.by"
             class="footer-text footer-text_icon mb-4"
          >
            <img class="me-2" src="/assets/svg/round-email.svg">
            <span class="fw-bold">
             info@els24.by
           </span>
          </a>
          <a href="https://goo.gl/maps/9Q6uQQRV15A7rWFL8"
             class="footer-text footer-text_icon mb-4"
             target="_blank"
          >
            <img class="me-2" src="/assets/svg/geo-alt.svg">
            <span class="fw-bold">
             220028, г. Минск, ул. Козыревская, д. 15, к. 903/1
           </span>
          </a>
          <div class="footer-text footer-text_icon ms-4">
            УНП 192734080<br>ООО “Столичное экспертно-правовое агентство”
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <div class="footer-text text-center text-md-start fw-bold mb-2 mt-5 mt-md-0">
            Лицензия №02240 / 2071 от 26.01.2017г.
          </div>
          <div class="footer-text text-center text-md-start">
            Выдана Министерством юстиции<br>Республики Беларусь
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12">
          <div class="footer-split"></div>
        </div>
        <div class="col-12 col-md-6 order-1 order-md-0">
          <div class="footer-text d-flex flex-column">
            <div class="fw-bold text-center text-md-start order-1 order-md-0 mb-md-2">
              © 2022 ЕВРОПЕЙСКАЯ ЮРИДИЧЕСКАЯ СЛУЖБА
            </div>
            <div class="text-center text-md-start order-0 order-md-1 mb-2 mb-md-0">
              Дистанционная юридическая поддержка
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 order-0 order-md-1">
          <div class="footer-text footer-text_decor text-center text-md-end mb-4 mb-md-0">
            <a href="/assets/docs/pravila-okazaniya-yur.uslug.pdf" class="fw-bold d-none text-white">
              Единые правила обслуживания для клиентов ЛИЧНЫЙ ЮРИСТ
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
.footer {
  background: #060A0F;
  padding: 40px 0;

  &-text {
    color: #FFFFFF;
    text-decoration: none;

    &_icon {
      display: flex;
      align-items: start;
    }

    &_decor {
      text-decoration: underline;
    }
  }

  &-split {
    width: 100%;
    border-top: 1px solid #525A63;
    margin: 56px 0 36px;
  }
}
</style>