<template>
  <section class="header-banner container">
    <div class="row">
      <div class="col-10 col-sm-6 col-md-6">
        <h1 class="header-banner_title">
          Юридические консультации онлайн 24/7
        </h1>
        <div class="header-banner_describe fs-l">
          Юридический сервис, актуальный для любого микро-, малого или среднего бизнеса
        </div>
        <div class="header-banner_describe fs-l fw-bold clr-gr">
          Мы готовы консультировать круглосуточно и по всем отраслям права
        </div>
      </div>
    </div>
  </section>
  <section class="header-banner_bottom">
    <div class="container">
      <div class="row row-cols-1 row-cols-md-3 g-2">
        <div class="col-12">
          <a class="header-banner_link fs-m clr-gr" href="/assets/docs/spisok.pdf"
             download>
            <img class="link-icon me-2" src="/assets/svg/pdf-icon.svg">
            <span>Список специалистов имеющих свидетельство об аттестации</span>
          </a>
        </div>
        <div class="col-6">
          <a class="header-banner_link fs-m clr-gr" href="/assets/docs/pravila_ministerstva.pdf"
             download>
            <img class="link-icon me-2" src="/assets/svg/pdf-icon.svg">
            <span>Правила оказания юр. услуг</span>
          </a>
        </div>
        <div class="col-6">
          <a class="header-banner_link fs-m clr-gr" href="/assets/docs/svidetelstvo.pdf"
             download>
            <img class="link-icon me-2" src="/assets/svg/pdf-icon.svg">
            <span>Свидетельство и лицензия</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Banner"
}
</script>

<style lang="scss" scoped>
.header-banner {

  @include _992 {
    padding-top: 120px;
    padding-bottom: 120px;
    background: url(/assets/png/header-banner-bg.png) no-repeat bottom right;
    background-size: contain;
  }
  @include _576 {
    background: url(/assets/png/header-banner-bg.png) no-repeat bottom right 0;
    background-size: contain;
  }
  padding-top: 48px;
  padding-bottom: 68px;
  background: url(/assets/png/header-banner-bg.png) no-repeat bottom right -150px;
  background-size: contain;

  &_bottom {
    padding: 0 0 60px 0;
  }

  &_title {
    @include _768 {
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 54px;
      color: #131F2F;
      margin: 0 0 18px 0;
    }
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 14px 0;
  }

  &_describe {
    &:last-child {
      @include _768 {
        max-width: 300px;
        margin: 60px 0 0 0;
      }
      margin: 24px 0 0 0;
      max-width: 180px;
    }

    @include _768 {
      max-width: 300px;
    }
    max-width: 215px;
  }

  &_link {
    display: flex;
    align-items: center;
    text-decoration: none;
    background: #F5F7F9;
    padding: 22px 30px;
    height: 100%;

    &:hover {
      background: #E6F0FB;
      color: #656D8B;
    }
  }
}
</style>